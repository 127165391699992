<template>
  <v-container class="d-flex justify-center align-center">
    <v-card class="pa-5">
      <!-- <v-form @submit.prevent="resetPassword"> -->
      <v-form ref="form" lazy-validation>
                  <!-- <v-text-field
                  name="email"
                  label="email"
                  type="email"
                  v-model="email"
                ></v-text-field> -->
        <v-text-field
          :type="show1 ? 'text' : 'password'"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show1 = !show1"
          :rules="[rules.required, rules.min]"
          v-model="password"
          label="New Password"
          required
        ></v-text-field>
        <v-text-field
          :type="show2 ? 'text' : 'password'"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          @click:append="show2 = !show2"
          v-model="confirmPassword"
          label="Confirm Password"
          required
        ></v-text-field>
        <!-- <v-btn type="submit" color="primary" @click="resetPassword"
          >Reset Password</v-btn
        > -->
      </v-form>
      <v-card-actions>
            <v-layout
              
                    class="text-center d-flex justify-center"
                  >
                    <toast
                    
                      @onClose="resetPassword"
                      :msg="toastText"
                         :buttonText =" saveText"
                      class="button-margin "
                      >
                    </toast>
                  </v-layout>
                  </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import toast from "./snackbar";
export default {
  components: {
    toast,
  
  },


  data() {
    return {
         saveText:"אפס סיסמה",
      email: "",
    //  toastText: 'הסיסמה שונתה בהצלחה',
     toastText: '',
      password: "",
      confirmPassword: "",
      show1: false,
      show2: false,
      rules: {
        required: (value) => !!value || "חובה.",
        min: (v) => v.length >= 4 || "מינימום 4 תווים",
      },
    };
  },

  computed: {
    clientInfo() {
      return this.$store.state.clientInfo;
    },
        users() {
      return this.$store.state.users;
    },
         resetToken() {
      return this.$store.state.resetToken;
    },

    mounted() {
  
    },
  },
  methods: {
    resetPassword() {
   
     
    
   
      if (this.$refs.form.validate() &&this.password === this.confirmPassword){
        // fetch("http://localhost:4000/users/resetPass", {
        fetch(`${process.env.VUE_APP_HOST}users/resetPass`, {
          method: "PUT",
          body: JSON.stringify({
  
            password: this.password,
            confirmPassword: this.confirmPassword,
              
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: 'Bearer ' + this.resetToken.token,
          },
        })
          .then((response) => response.json())
          .then((jsonObject) => {
            console.log(jsonObject);
            if (jsonObject.error == false) {
              this.toastText ="הסיסמה שונתה בהצלחה"
              
            } else {
                this.toastText ="פג תוקף הקישור"
            }
            

          });
      } else {
         this.toastText ="סיסמאות אינן תואמות"
        // alert("סיסמאות אינן תואמות");
      }
    },
  },
};
</script>
<style></style>
