import { merge } from "lodash";
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
// import createPersistedState from "vuex-plugin-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
  // ...
  plugins: [createPersistedState()],
});

async function localJson() {
  const response = await fetch("../games.json"); // Replace with your actual URL
  const games = await response.json();
  return games;
}
async function realApi() {
  const response = await fetch(
    "https://api-football-v1.p.rapidapi.com/v3/fixtures?league=2&season=2022",
    {
      method: "GET",
      headers: {
        "x-rapidapi-key": "a93678359bmshec209499c207ebep1758d1jsn22a0484dd70e",
        "x-rapidapi-host": "api-football-v1.p.rapidapi.com",
      },
    }
  );
  const data = await response.json();
  const games = data.response;

  return games;
}

async function gamesToStorage() {
   let games = null;
            games = await realApi();
          console.log("real games");

  const dataToStore = {
    date: new Date(),
    data: games,
  };


  localStorage.setItem("newJson", JSON.stringify(dataToStore));
  return dataToStore;
}

   async function  realOdds() {

const options = {
	method: 'GET',
	headers: {
		'X-RapidAPI-Key': 'a93678359bmshec209499c207ebep1758d1jsn22a0484dd70e',
		'X-RapidAPI-Host': 'api-football-v1.p.rapidapi.com'
	}
};

const response = await fetch('https://api-football-v1.p.rapidapi.com/v3/odds?league=2&season=2022', options)
  const data = await response.json();
  const odds = data.response;
console.log(odds)
  return odds;


      }


      



export default new Vuex.Store({
  state: {
    plugins: [],
    table: [],
    resultarr: [],
    games: [],
    users: [],
    guesses: [],
    // שם וID של המשתמש
    clientInfo: {},
    items: [],
    localHost: process.env.VUE_APP_HOST,
    odds: [],
    mergedData: [],
    resetToken: {},
  },
  getters: {},

  actions: {
    // LOCAL JSON odds
    async fetchOdds({ commit, state }) {
      // console.log(state.newJson);
      const israelTime = new Date().toLocaleString("en-US", {
        timeZone: "Asia/Jerusalem",
      });
      const dayOfWeek = new Date(israelTime).getDay(); // Sunday is 0, Monday is 1, etc.
      const hourOfDay = new Date(israelTime).getHours();
      // console.log(dayOfWeek )
      // console.log(hourOfDay);
      // console.log(israelTime);

      try {
        let games = null;
    
        if ((dayOfWeek === 2 || dayOfWeek === 3 ||dayOfWeek ===6 ) && hourOfDay >= 22) {
          // if ((dayOfWeek === 2 || dayOfWeek === 1) && hourOfDay >= 18) {
          games = await realApi();
          console.log("real games");

          const dataToStore = {
            date: new Date(),
            data: games,
          };


          localStorage.setItem("newJson", JSON.stringify(dataToStore));
          console.log(dataToStore)
          games = dataToStore.data
        } else {
          // localStorage.removeItem("newJson");
          if (localStorage["newJson"]) {
          
            const storedData = JSON.parse(localStorage.newJson);
            const storedDate = new Date(storedData.date);
            const currentDate = new Date();
            // console.log("local");
            if (currentDate.getDate() !== storedDate.getDate()) {
              console.log("local games - expired");
              
              localStorage.removeItem("newJson");
              
              games = await gamesToStorage()
              console.log(games)
              games = games.data
            } else {
          console.log('local games')
              games = JSON.parse(localStorage.newJson);
               games = games.data;
            }
          } else {
            games = await gamesToStorage();
            games = games.data
          }
        }

      
      

        // console.log(games);

        // לפי תאריך
        // games .sort((p1, p2)=>{
        // return      p1.fixture.date > p2.fixture.date
        //           ? 1
        //           : p1.fixture.date < p2.fixture.date
        //           ? -1
        //           : 0
        // })

        games.forEach((object, key) => {
          object.fixture.original_id = object.fixture.id;
          object.fixture.id = key;
          object.fixture.time = object.fixture.date.substr(11, 5);
          object.fixture.datetoShow = object.fixture.date.substr(0, 10);
          object.fixture.time = "22:00";
          if (object.league.round === "Round of 16") {
            object.league.round = "שמינית גמר";
          } else if (object.league.round === "Quarter-finals") {
            object.league.round = "רבע גמר";
          } else if (object.league.round === "Semi-finals") {
            object.league.round = "חצי גמר";
          }
        });
        // localStorage.removeItem("newOdds");
     
        let response1 = await fetch("../oddscl.json");
        let odd = null;
if (localStorage["newOdds"]) {
  const storedData = JSON.parse(localStorage.newOdds);
  const storedDate = new Date(storedData.date);
  const currentDate = new Date();
// console.log(storedDate);
// console.log(currentDate);
  if (currentDate.getDate() !== storedDate.getDate()) {
    console.log("local odds - expired");
    localStorage.removeItem("newOdds");
  } else {
    console.log("local odds");
    const responseJson = await response1.json();
    odd = await storedData.data;
  }
}

if (!localStorage["newOdds"]) {
  console.log("real odds");
  // const responseJson = await response1.json();
  const responseJson = await realOdds();

  odd = responseJson;
  const dataToStore = {
    date: new Date(),
    data: odd,
  };
  localStorage.setItem("newOdds", JSON.stringify(dataToStore));
}


 

        // if (localStorage["newOdds"]) {
        //   console.log("local odds");
        //     const responseJson = await response1.json();
        //   odd = JSON.parse(localStorage.newOdds);
        // } else {
        //   console.log("real odds");
        //   const responseJson = await response1.json();
        //   odd = responseJson;
        //   localStorage.setItem("newOdds", JSON.stringify(odd));
        // }

        // const response1 = await fetch("../oddscl.json");
        // const odd = await  response1.json();
       

        // const mergedData = [...data, ...odd];
      const oddsObj =  {};
        for (let i = 0; i < odd.length; i++) {
         const odds =  odd[i];
          const fixtureId = odds.fixture.id;
          if (!oddsObj[fixtureId]) {
            // oddsObj[fixtureId] = [];// ass array
            oddsObj[fixtureId] = {}; //ass object
          }
          // oddsObj[fixtureId].push(odds.bookmakers[0].bets[0].values[0].odd);// ass array
          oddsObj[fixtureId][odds.bookmakers[0].bets[0].values[0].value] =
            Math.round(odds.bookmakers[0].bets[0].values[0].odd * 2) / 2; //ass object
          oddsObj[fixtureId][odds.bookmakers[0].bets[0].values[1].value] =
            Math.round(odds.bookmakers[0].bets[0].values[1].odd * 2) / 2; //ass object
          oddsObj[fixtureId][odds.bookmakers[0].bets[0].values[2].value] =
            Math.round(odds.bookmakers[0].bets[0].values[2].odd * 2) / 2; //ass object
        }
        // Loop through fixtures data to add odds data based on fixture ID
        const mergedData = [];
        for (let i = 0; i < games.length; i++) {
          const fixture =  games[i];
          // const odds = oddsObj[fixture.fixture.id];
          const odds = oddsObj[fixture.fixture.original_id];
          mergedData.push({
            ...fixture,
            odds,
          });
        }
        // console.log(mergedData)
        commit("setMergedData", mergedData);
        return  mergedData;
      } catch (error) {
        // Handle any errors that may occur
        console.error("Error fetching data from JSON 1:", error);
        throw error; // Rethrow the error to be caught by the caller
      }
    
    },


  },
  mutations: {
    increment(state, clientInfo) {
      state.clientInfo = clientInfo;
    },
    setGames(state, games) {
      state.games = games;
    },
    setItems(state, items) {
      state.items = items;
    },
    setOdds(state, odds) {
      state.odds = odds;
    },
    setMergedData(state, mergedData) {
      state.mergedData = mergedData;
    },
    setUsers(state, users) {
      state.users = users;
    },
    setGuesses(state, guesses) {
      state.guesses = guesses;
    },
    setToken(state, resetToken) {
      state.resetToken = resetToken;
    },
  
  },
  modules: {},
});
