<template>
    <v-container class="d-flex justify-center align-center">
      <v-card class="pa-5">
        <v-alert type="success" icon="mdi-check-bold" dense>
נשלח אליך אימייל עם קישור לאיפוס סיסמה. קישור זה תקף ל3 דקות בלבד
        </v-alert>
        <v-btn color="primary" @click="goToLoginPage">התחברות</v-btn>
      </v-card>
    </v-container>
  </template>
  
  <script>
  export default {
    methods: {
      goToLoginPage() {
        // Navigate to the login page
        this.$router.push('/');
      }
    }
  }
  </script>