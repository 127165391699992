<template>
  <div class="page"
     v-if="Object.keys(this.clientInfo).length 
     !== 0">
    <v-container class="">
     
      <v-row class="mx-auto text-center">
        <v-col cols="12">
          <h2>סטטיסטיקות</h2>
        </v-col>
        <v-col>
          <h3 v-for="(item, index) in allUsers" :key="index" class="text-red">
            סה"כ: {{ item.points }} נק'
          </h3>
        </v-col>
      </v-row>
      <v-simple-table
        fixed-header
        class="table mx-auto mt-5 table-width simple-table-color"
        calculate-widths
      >
        <template v-slot:default>
          <thead>
            <!-- <tr>
            <th class="">שם</th>
            <th class="">נקודות</th>
          </tr> -->
          </thead>
          <tbody v-for="(user, i) in allUsers" :key="i">
         
            <tr 
        
            class="font-weight-bold title text-white">
              <td class="numbers pa-4 white--text title text">סה"כ ניחושים</td>
              <td class="name text-right white--text title">
                {{ userGuess.length }}
              </td>
              <!-- <v-spacer></v-spacer> -->
            </tr>
           
            <tr class="font-weight-bold title">
              <td class="td-direct-color title">סה"כ ניחושים מדוייקים</td>
              <td class="name text-right white--text title">
                <!-- {{ user.direct }} -->
                {{ direct.length }}
              </td>
              <!-- <v-spacer></v-spacer> -->
            </tr>
            <tr class="font-weight-bold title">
              <td class="td-direction-color title">סה"כ ניחושי כיוון</td>
              <td class="name text-right white--text title">
                <!-- {{ user.direction }} -->
                {{direction.length }}
              </td>
              <!-- <v-spacer></v-spacer> -->
            </tr>
            <tr class="font-weight-bold title">
              <td class="td-miss-color title">סה"כ פספוסים</td>
              <td class="name text-right white--text title">
                {{ userGuess.length - direct.length -  direction.length}}
              </td>
              <!-- <v-spacer></v-spacer> -->
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
  </div>
  <div v-else class="page">
    <v-main>
    <noProfile :msg="pageText"/>
    </v-main>
  </div>
</template>

<script>
import noProfile from './noProfile'
export default {

  metaInfo: {
    title: 'סטטיסטיקה'
  },

  components: {
    noProfile,
  },


  data() {
    return {
      allUsers: [],
      userGuess: [],
      pageText:" סטטיסטיקה פעילה",
      direct: [],
      direction: [],
    };
  },

  mounted() {
    this.getThisUserGusses()
    this.getUserDetails();
    // console.log(this.guesses);
  },

  computed: {
    users() {
      return this.$store.state.users;
    },
    clientInfo() {
      return this.$store.state.clientInfo;
    },
    guesses() {
      return this.$store.state.guesses;
    },
  },
  methods: {
    getUserDetails() {
      fetch(`${process.env.VUE_APP_HOST}users/${this.clientInfo.id}`,
{    headers: {
          Authorization: 'Bearer ' + this.clientInfo.token,
        },}
      )
        .then((response) => response.json())
        .then((jsonObject) => {
          for (let i = 0; i < jsonObject.length; i++) {
            this.allUsers.push(jsonObject[i]);
          }
        });
    },
        getThisUserGusses() {
      fetch(`${process.env.VUE_APP_HOST}guesses/${this.clientInfo.id}`)
        .then((response) => response.json())
        .then((jsonObject) => {
this.userGuess = jsonObject
// console.log(this.userGuess)
this.direct = this.userGuess.filter((item)=>{
 return item.direct == 1
})

this.direction = this.userGuess.filter((item)=>{

return item.direction ==1 
})

        });
    },
  },
};
</script>

<style scoped>
.table-width {
  max-width: 500px !important;
}
.td-direction-color {
  color: #b2d7d7 !important;
}
.td-direct-color {
  color: #02cdcd !important;
}
.td-miss-color {
  color: #fa0359 !important;
}
.page {
  background-image: linear-gradient(
    to right,
    rgba(8, 50, 144, 255),
    rgba(1, 88, 205, 255)
  ) !important;
  height: 873px;
}
.white {
  color: #fbf3f4;
}

h3 {
  color: #ec707c;
}
h2 {
  color: #fbf3f4;
}
.simple-table-color {
  background-color: transparent !important;
}
tr:hover {
  background-color: transparent !important;
}



</style>
