<template>
  <div>
    <head>
      <!-- <title>{{ $metaInfo.meta }}</title> -->
      <!-- <meta name="description" :content="$metaInfo.meta.description"> -->
      <!-- <meta property="og:type" content="website"> -->
<!-- <meta property="og:title" content="Fat Cattitude"> -->
<!-- <meta property="og:image" content="https://cdn.com/fat-cattitude-logo.jpg"> -->
<!-- <meta property="og:description" content="There’s attitude and there’s CATTITUDE..."> -->
      <!-- Add other meta tags here -->
    </head>
<v-app class="page-color">
  <toolbar ></toolbar>
  <v-main>
    <!-- <v-container fluid class=""> -->
      <router-view/>
    <!-- </v-container > -->
  </v-main>
  </v-app>
  </div>
</template>

<script>
import toolbar from "./components/toolbar";
import Tables from "./views/tableView";
import  Match  from "./views/matchesView";
export default {
  name: 'App',
    metaInfo: {
      title: 'משחק הניחושים של ליגת האלופות',
      titleTemplate: '%s | משחק הניחושים של ליגת האלופות',
       htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'An example Vue application with vue-meta.' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
    },



    components: {
      toolbar,
      Tables,
    Match
  },

  data: () => ({
 
  }),

  beforeMount() {
    
    this.checkLocal();
    this.checkLocalb();
    this.getAllUsers()
    this.checkToken()

  },

  mounted() {
   
  },

  computed: {
       clientInfo() {
      return this.$store.state.clientInfo;
    },
      items() {
      return this.$store.state.items;
    },
       resetToken() {
      return this.$store.state.resetToken;
    },

  },

  methods: {
       checkLocal() {
      if (localStorage["user"]) {
        this.$store.commit("increment", JSON.parse(localStorage.user));
 
      }
    },
      checkLocalb() {
      if (localStorage["test"]) {
        this.$store.commit("setItems", JSON.parse(localStorage.test));
     
      }
    },
    checkToken() {
      if (localStorage["token"]) {
      
        this.$store.commit("setToken", JSON.parse(localStorage.token))
      }
    },

    getAllUsers() {
      fetch(`${process.env.VUE_APP_HOST}users/register`,{ headers: {
          Authorization: 'Bearer ' + this.clientInfo.token,
        },})
        .then((response) => response.json())
        .then((jsonObject) => {
          // this.usersapp = jsonObject
     this.$store.commit("setUsers", jsonObject)
        });
    },



  },
};
</script>
<style >

.page-color {
  /* background-color: #0161db; */
  /* background-color: #0353c5; */
   background-image: linear-gradient(to right, rgba(8,50,144,255), rgba(1,88,205,255));
  
}
</style>