<template>
  <v-container >
    <v-main    class="  mx-auto text-center  text-color">
        <h2>אין לך {{ msg }} 

        </h2>
        <h1>הירשם והתחל לנחש יחד עם החברים

        </h1>
        <v-btn color="#db4a4a" class="mt-10 pa-5"
        width="150"
        regular>
            <router-link
          
             class=""
              style="text-decoration: none; color:#fefefa"
           
             to="/">
התחברות
            </router-link>
        </v-btn>
    </v-main>
  </v-container>
</template>

<script>
export default {
name: "noProfile",

props:{
msg: String
},
}
</script>

<style>
 .text-color{
    color: #d2b9ca;
    /* color: #ccf5ff; */
 }
</style>