

export function linkToAwayTeam(i) {
  console.log("link");
  let foo = i;

  switch (foo) {
    case "185":
      window.location.href =
        "https://www.365scores.com/he/football/england/tottenham/team/114";
      break;
    case "186":
      window.location.href =
        "https://www.365scores.com/he/football/italy/ac-milan/team/227";
      break;
    case 3:
      window.location.href =
        "https://www.365scores.com/he/football/england/tottenham/team/114";
      break;
    case "187":
      window.location.href =
        "https://www.365scores.com/he/football/germany/bayern-munich/team/331";
      break;
    case "188":
      window.location.href =
        "https://www.365scores.com/he/football/france/psg/team/480";
      break;
    case "189":
      window.location.href =
        "https://www.365scores.com/he/football/portugal/benfica/team/888";
      break;
    case "190":
      window.location.href =
        "https://www.365scores.com/he/football/belgium/club-brugge/team/1169";
      break;
    case "191":
      window.location.href =
        "https://www.365scores.com/he/football/england/chelsea/team/106";
      break;
    case "192":
      window.location.href =
        "https://www.365scores.com/he/football/germany/dortmund/team/341";
      break;
    case "193":
      window.location.href =
        "https://www.365scores.com/he/football/spain/real-madrid/team/131";
      break;
    case "194":
      window.location.href =
        "https://www.365scores.com/he/football/england/liverpool/team/108";
      break;
    case "195":
      window.location.href =
        "https://www.365scores.com/he/football/italy/napoli/team/234";
      break;
    case "196":
      window.location.href =
        "https://www.365scores.com/he/football/germany/eintracht-frankfurt/team/337";
      break;
    case "197":
      window.location.href =
        "https://www.365scores.com/he/football/england/manchester-city/team/110";
      break;
    case "198":
      window.location.href =
        "https://www.365scores.com/he/football/germany/rb-leipzig/team/7171";
      break;
    case "199":
      window.location.href =
        "https://www.365scores.com/he/football/portugal/fc-porto/team/887";
      break;
    case "200":
      window.location.href =
        "https://www.365scores.com/he/football/italy/inter-milan/team/224";
      break;
      case "209":
        window.location.href =
          "https://www.365scores.com/he/football/team/manchester-city-110";
        break;
      case "211":
        window.location.href =
          "https://www.365scores.com/he/football/team/inter-milan-224";
        break;
      case "212":
        window.location.href =
          "https://www.365scores.com/he/football/team/ac-milan-227";
        break;
      case "210":
        window.location.href =
          "https://www.365scores.com/he/football/team/real-madrid-131";
        break;

    default:
  }
}



export function linkToHomeTeam(i) {
  let foo = i;

  switch (foo) {
    case "186":
      window.location.href =
        "https://www.365scores.com/he/football/england/tottenham/team/114";

      break;
    case "185":
      window.location.href =
        "https://www.365scores.com/he/football/italy/ac-milan/team/227";
      break;
    case 3:
      window.location.href =
        "https://www.365scores.com/he/football/england/tottenham/team/114";
      break;
    case "188":
      window.location.href =
        "https://www.365scores.com/he/football/germany/bayern-munich/team/331";
      break;
    case "187":
      window.location.href =
        "https://www.365scores.com/he/football/france/psg/team/480";
      break;
    case "189":
      window.location.href =
        "https://www.365scores.com/he/football/belgium/club-brugge/team/1169";
      break;
    case "190":
      window.location.href =
        "https://www.365scores.com/he/football/portugal/benfica/team/888";
      break;
    case "192":
      window.location.href =
        "https://www.365scores.com/he/football/england/chelsea/team/106";
      break;
    case "191":
      window.location.href =
        "https://www.365scores.com/he/football/germany/dortmund/team/341";
      break;
    case "194":
      window.location.href =
        "https://www.365scores.com/he/football/spain/real-madrid/team/131";
      break;
    case "193":
      window.location.href =
        "https://www.365scores.com/he/football/england/liverpool/team/108";
      break;
    case "196":
      window.location.href =
        "https://www.365scores.com/he/football/italy/napoli/team/234";
      break;
    case "195":
      window.location.href =
        "https://www.365scores.com/he/football/germany/eintracht-frankfurt/team/337";
      break;
    case "198":
      window.location.href =
        "https://www.365scores.com/he/football/england/manchester-city/team/110";
      break;
    case "197":
      window.location.href =
        "https://www.365scores.com/he/football/germany/rb-leipzig/team/7171";
      break;
    case "200":
      window.location.href =
        "https://www.365scores.com/he/football/portugal/fc-porto/team/887";
      break;
    case "199":
      window.location.href =
        "https://www.365scores.com/he/football/italy/inter-milan/team/224";
      break;
    case "209":
      window.location.href =
        "https://www.365scores.com/he/football/team/real-madrid-131";
      break;
    case "211":
      window.location.href =
        "https://www.365scores.com/he/football/team/ac-milan-227";
      break;
    case "212":
      window.location.href =
        "https://www.365scores.com/he/football/team/inter-milan-224";
      break;
    case "210":
      window.location.href =
        "https://www.365scores.com/he/football/team/manchester-city-110";
      break;

    default:
  }

}




