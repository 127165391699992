<template>
 

  <v-main>
<fixtures></fixtures>

  </v-main>

</template>

<script>

import fixtures from "../components/fixtures";

export default {
  name: "Match",

  components: {
   fixtures,
    
  },
};
</script>