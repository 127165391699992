<template>
 

  <v-main
      v-if="Object.keys(this.clientInfo).length 
     !== 0">
 
<v-row class=" d-flex justify-space-around button-wrapper mx-auto">
      <v-btn-toggle
        v-model="text"
        tile
        color="deep-purple accent-3 "
        group
      >
    

        <v-btn value="true"
        :disabled="text === 'true'">
         הטבלה שלנו
        </v-btn>

        <v-btn value="false"
        :disabled="text === 'false'">
          התוצאות שלנו
        </v-btn>

   
      </v-btn-toggle>
<!-- <v-btn @click="showTable = !showTable ">הטבלה שלנו</v-btn> -->
<!-- <v-btn @click="showTable = !showTable"
:disabled="showTable === true">הטבלה שלנו</v-btn> -->
<!-- <v-spacer></v-spacer> -->
<!-- <v-btn @click="showResultsTable = !showResultsTable"
:disabled="showResultsTable === true">התוצאות שלנו</v-btn> -->
</v-row>

<tables v-show="text ==='true'"></tables>
<carousel v-show="text === 'false'"></carousel>
  </v-main>
  <div v-else class="page">
    <v-main>
    <noProfile :msg="pageText"/>
    </v-main>
  </div>

</template>

<script>

import tables from "../components/table";
import carousel from "../components/carousel";
import noProfile from '../components/noProfile'

export default {
  name: "Tables",
    metaInfo: {
    title: 'טבלה'
  },



  data() {
    return {
showTable: true,
showResultsTable: false,
      text: "true",
pageText: "קבוצה פעילה"
    };
  },



  components: {
    tables,
    carousel,
    noProfile
    
  },

  computed: {
        clientInfo() {
      return this.$store.state.clientInfo;
    },
  },

methods:{
  tableButton(){
    this.showTable = false
  }
}


};
</script>
<style scoped>
.button-wrapper{
  /* width: 700px; */
}
.page {
  background-image: linear-gradient(
    to right,
    rgba(8, 50, 144, 255),
    rgba(1, 88, 205, 255)
  ) !important;
  height: 873px;
}
</style>